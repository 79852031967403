import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainer } from "../components/micros"

const Pact = () => (
    <Layout>
        <Seo title="Devil pact" />
        <SectionContainer style={{ paddingTop: "100px" }}>
            <h1>There is no pact with the devil :(</h1>
            <span>It's just a joke</span>
            <h5> But if you are the devil – send us an email!</h5>
            {/* <span> Send us an email to </span><br /> */}
            {/* <a href="mailto:contact@summonjob.com">contact@summonjob.com </a> */}
        </SectionContainer>
    </Layout>
)

export default Pact
